import React from 'react';

import Alert from '@ui/Alert';

interface Props extends React.PropsWithChildren<unknown> {}

interface State {
  error?: Error;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  fullMessage() {
    const { error } = this.state;
    if (!error) return '';

    return <pre>{error.stack}</pre>;
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <Alert subtitle={this.fullMessage()} title="The component preview encountered an error!" type="danger" />;
    }

    return children;
  }
}

export default ErrorBoundary;
