export const newStateExample = `---
title: New Component
version: 1.0
author: Owlbert
exports: Component
---

export const Component = ({ children }) => {
  return (
    <div className="flex items-center h-full w-full">
      <div className="bg-gray-100 rounded-md p-6 m-12">
        {children}
      </div>
    </div>
  );
};

<Component>
  Here's a very simple example component rather than an empty state. This should help 
  you figure out what's happening quicker and see what's possible with custom components!
</Component>
`;
