import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';

import Badge from '@ui/Badge';
import Button from '@ui/Button';
import CodeInput from '@ui/CodeInput';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import RDMD from '@ui/RDMD';
import ErrorBoundary from '@ui/RDMD/ErrorBoundary';
import { RHFGroup } from '@ui/RHF';
import Tooltip from '@ui/Tooltip';

import { useCustomBlockFormContext } from '../Context';

import { newStateExample } from './newStateExample';
import classes from './style.module.scss';

export default function ComponentEditor() {
  const bem = useClassy(classes, 'ComponentEditor');
  const { control } = useCustomBlockFormContext();
  const sourceValue = useWatch({ control, name: 'source' });
  const isNewComponent = window.location.hash === '#/content/custom-components/new';
  const [currentField, setCurrentField] = useState(isNewComponent ? newStateExample : sourceValue);
  const [layout, setLayout] = useState('row');
  const [theme, setTheme] = useState('dark');

  const toggleLayout = () => {
    setLayout(prevLayout => (prevLayout === 'row' ? 'col' : 'row'));
  };

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  const onFieldChange = (value: string) => {
    setCurrentField(value);
  };

  return (
    <Flex className={bem('&')} data-testid="ComponentEditor" gap={0} layout={layout}>
      <div className={bem('-inputWrapper')} data-label="index.mdx">
        <RHFGroup control={control} name="source" required>
          {({ field }) => (
            <CodeInput
              {...field}
              className={bem('-codeInput')}
              initialValue={currentField}
              language="jsx"
              onChange={e => {
                field.onChange(e);
                onFieldChange(e);
              }}
              size="sm"
            />
          )}
        </RHFGroup>
      </div>
      <div className={bem('-preview', `-preview_${layout}`, `-preview_${theme}`)}>
        <div className={bem('-preview-header')}>
          <Flex align="center">
            <Badge allCaps circular={false} kind={theme === 'dark' ? 'dark' : 'light'}>
              Preview
            </Badge>
          </Flex>
          <div className={bem('-preview-header-controls')}>
            <Tooltip content={theme === 'dark' ? 'Set Light Mode' : 'Set Dark Mode'} placement="bottom-end">
              <Button ghost kind="contrast" onClick={toggleTheme} size="sm">
                <Icon name={theme === 'dark' ? 'moon' : 'sun'} />
              </Button>
            </Tooltip>
            <Tooltip content={layout === 'row' ? 'Split Horizontal' : 'Split Vertical'} placement="bottom-end">
              <Button ghost kind="contrast" onClick={toggleLayout} size="sm">
                <Icon name={layout === 'row' ? 'columns-2' : 'rows-2'} />
              </Button>
            </Tooltip>
            <Tooltip content="Info" placement="bottom-end">
              <Button ghost kind="contrast" size="sm">
                <Icon name="help-circle" />
              </Button>
            </Tooltip>
          </div>
        </div>
        <Flex align="center" className={bem('-preview-render')}>
          <ErrorBoundary>
            <RDMD body={currentField} mdx />
          </ErrorBoundary>
        </Flex>
      </div>
    </Flex>
  );
}
