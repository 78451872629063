import type { BaseRange } from 'slate';

import { Editor, Transforms } from 'slate';

import { ReusableContent, MenuHandle, GlossaryTerm } from '@ui/MarkdownEditor/editor/blocks';
import { MenuActionTypes } from '@ui/MarkdownEditor/enums';

import { openReusableContentMenu } from '../blocks/ReusableContent/operations';

const afterAngleBracket = (editor: Editor) => {
  if (editor.selection?.anchor.offset === 0) return false;

  const anchor = editor.selection!.anchor;
  const focus = { path: anchor.path, offset: anchor.offset - 1 };
  const char = Editor.string(editor, { anchor, focus });

  return char === '<';
};

const insertOrCleanUp = (editor: Editor, { closeTag = false }) => {
  const [{ filtered, selected, rangeRef }, dispatch] = editor.reusableContentMenu;

  if (!rangeRef) {
    // eslint-disable-next-line no-console
    console.warn('Reusable content menu appears closed?');
    return;
  }

  const block = filtered[selected];

  if (closeTag) {
    Transforms.insertText(editor, '>');
  } else if (block && 'tag' in block) {
    ReusableContent.operations.insertReusableContent(editor, block.tag, { at: rangeRef.current as BaseRange });
  } else if (block && 'term' in block) {
    GlossaryTerm.operations.insertGlossaryTerm(editor, block.term, { at: rangeRef.current as BaseRange });
  } else {
    Transforms.insertText(editor, ' ');
  }

  dispatch({ type: MenuActionTypes.close });

  const entry = Editor.above(editor, { at: rangeRef.current as BaseRange, match: MenuHandle.is });
  if (entry) Transforms.unwrapNodes(editor, { at: entry[1] });
};

const onKeyDown = (event: KeyboardEvent, editor: Editor) => {
  const { disallowCustomBlocks, useMDX } = editor.props;
  if (disallowCustomBlocks && !useMDX) return;

  const [{ open, rangeRef }, dispatch] = editor.reusableContentMenu;

  if (open) {
    if (!rangeRef) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case '>': {
        event.preventDefault();
        event.stopPropagation();

        insertOrCleanUp(editor, { closeTag: event.key === '>' });

        break;
      }
      case 'ArrowDown':
      case 'ArrowUp': {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: event.key === 'ArrowDown' ? MenuActionTypes.down : MenuActionTypes.up });

        break;
      }
      case 'Escape':
      case '<': {
        // don't stop propagation if the keypress is '<'
        // so the event still triggers the variable menu
        if (event.key !== '<') {
          event.preventDefault();
          event.stopPropagation();
        }

        const entry = Editor.above(editor, { at: rangeRef.current as BaseRange, match: MenuHandle.is });
        if (entry) Transforms.unwrapNodes(editor, { at: entry[1] });

        dispatch({ type: MenuActionTypes.close });

        break;
      }
      default:
        break;
    }
  }

  if (event.key === '<' && !afterAngleBracket(editor)) {
    event.preventDefault();
    event.stopPropagation();

    openReusableContentMenu(editor, true);
  }
};

export default onKeyDown;
